import React, { useCallback, useState } from "react";
import { errorModal } from "../utils";
import { Button, Fade } from "@material-ui/core";

import { Styles } from "../styles";
import { Theme } from "../../../config";
import ThemeProviderComponent from "../../others/ThemeProvider";

type Infos = {
  title?: string;
  text?: string;
  confirmButton?: { label: string; onClick: () => void };
  exitButton?: { label: string; onClick: () => void } ; 
};

function ModalContainer() {
  const [infos, setInfos] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const { title, text, confirmButton, exitButton } = infos;

  const handleModal = useCallback(
    (config: { open: boolean; infos: Infos }) => {
      if (config.open) {
        setInfos(config.infos);
      }
      setOpen(config.open);
    },
    [setInfos, setOpen]
  );

  errorModal.setCallback(handleModal);

  return (
    <ThemeProviderComponent theme={Theme.Light}>
      <Styles.StyledModal
        open={open}
        onClose={() => setOpen(false)}
        BackdropComponent={Styles.StyledBackdrop}
        aria-labelledby="Título do modal"
        aria-describedby="Descrição do modal"
        disableBackdropClick
      >
        <Fade in={open}>
          <Styles.Container>
            <Styles.ContentContainer>
              <Styles.HeaderContainer>
                <Styles.Title>{title}</Styles.Title>

                {/* <IconButton onClick={errorModal.close}>
                  <Styles.Close />
                </IconButton> */}
              </Styles.HeaderContainer>
              <Styles.HeaderContainer>
                <Styles.ContentText>{text}</Styles.ContentText>
              </Styles.HeaderContainer>
            </Styles.ContentContainer>
            <Styles.FooterContainer>
              {confirmButton && (
                <Styles.ConfirmButton fullWidth={false} onClick={confirmButton.onClick}>
                  {confirmButton?.label}
                </Styles.ConfirmButton>
              )}
              {exitButton && (
                <Styles.ExitButton fullWidth={false} onClick={exitButton.onClick}> {exitButton?.label}</Styles.ExitButton>
              )}
            </Styles.FooterContainer>
          </Styles.Container>
        </Fade>
      </Styles.StyledModal>
    </ThemeProviderComponent>
  );
}

export default React.memo(ModalContainer);
