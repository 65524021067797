import { create, RequestTransform } from "apisauce";
import caller from "./helpers/caller";

import { SessionStorage } from "../utils";

const apiUrl = "https://autenticador.palmsoft.com.br/token";

const api = create({
  baseURL: apiUrl,
  timeout: 500000,
});

api.addRequestTransform((request: any) => {
  const token = SessionStorage.getItem("token");

  if (token) {
    request.headers.authorization = token;
  }
});

api.addMonitor((response: any) => {
  const token = response.headers.authorization;

  if (token) {
    SessionStorage.setItem("token", token);
  }
});

async function getToken(loginData) {
  return caller(api.post, "/otp/login", null, loginData);
}
async function deleteEmail() {
  return caller(api.delete, "/otp/delete", null, {
    email: "eduzinhu_6@gmail.com",
  });
}

const apiServices = {
  getToken, deleteEmail
};

export default apiServices;
