import { Button } from "@material-ui/core";
import React, { useContext } from "react";
import { Spacing } from "../../config";
import { StorageContext } from "../../contexts/StorageContext";
import Styles from "./HomeStyles";

function Home() {
  const [isMounted, setMount] = React.useState<boolean>(false);
  const { setIsLogged } = useContext<any>(StorageContext);
  const Mount = React.useCallback(() => {}, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  return (
    <Styles.ImageBG>
      <Styles.Content>
        <div>
          <Styles.Title>Autenticação Completa</Styles.Title>
          <Button
            onClick={() => setIsLogged(false)}
            style={{ marginTop: Spacing(5) }}
            variant="contained"
          >
            Voltar
          </Button>
        </div>
      </Styles.Content>
    </Styles.ImageBG>
  );
}

export default Home;
