type ErrorModalInfos = {
  title?: string;
  text?: string;
  confirmButton?: { label: string; onClick: () => void };
  exitButton?: { label: string; onClick: () => void };
};

type ErrorModalCallback = (params: {
  open: boolean;
  infos: ErrorModalInfos;
}) => void;

let errorModalCallback: ErrorModalCallback | null = null;

function setErrorModalCallback(callback: ErrorModalCallback) {
  errorModalCallback = callback;
}

function setErrorModalInfos(
  title?: string,
  text?: string,
  confirmButton?: { label: string; onClick: () => void },
  exitButton?: { label: string; onClick: () => void }
) {
  if (errorModalCallback) {
    errorModalCallback({
      open: true,
      infos: { title, text, exitButton, confirmButton },
    });
  }
}

function closeErrorModal() {
  if (errorModalCallback) {
    errorModalCallback({
      open: false,
      infos: {},
    });
  }
}

const errorModal = {
  setCallback: setErrorModalCallback,
  setInfos: setErrorModalInfos,
  close: closeErrorModal,
};

export { errorModal };
