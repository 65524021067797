import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Styles from "./LoginStyles";

import { hooks, SessionStorage } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";
//import packageJson from "../../../package.json";

import { api } from "../../services";
import { paths } from "../../navigation/navigate";
import { Button, TextField } from "@material-ui/core";
import { Spacing } from "../../config";
import { errorModal } from "../../components/modals/utils";

function Login() {
  //const version = packageJson.version;
  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const { addData, setIsLogged } = useContext<any>(StorageContext);
  const [email, setEmail] = React.useState<string>("");
  const [value, setValue] = React.useState<string>("");

  const [emailError, setEmailError] = React.useState<string>("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const testEmail = () => {
    // Verifica se o valor inserido é um e-mail válido
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      setEmailError("Insira um e-mail válido.");
    } else {
      setEmailError("");
    }
  };

  React.useEffect(() => {
    call("", api.deleteEmail(), () => {});
  }, []);
  const submitToken = () => {
    call("", api.getToken({ email: email, password: value }), (response) => {
      if (response.ok) {
        setIsLogged(true);
      } else {
        errorModal.setInfos("Erro", response?.data?.msg, {
          label: "Ok",
          onClick: () => {
            errorModal.close();
          },
        });
      }
    });
  };

  return (
    <Styles.ImageBG>
      <Styles.Content>
        <div>
          <Styles.Title>Teste Autenticação</Styles.Title>
          <Styles.Subtitle>Insira o token abaixo:</Styles.Subtitle>
        </div>

        <div>
          <form
            style={{ display: "flex", flexDirection: "column" }}
            noValidate
            autoComplete="off"
          >
            <TextField
              onBlur={testEmail}
              style={{ marginBottom: "8px" }}
              type="email"
              value={email}
              onChange={handleEmailChange}
              id="outlined-basic-email"
              label="E-mail:"
              variant="outlined"
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              disabled={email.length < 1}
              value={value}
              inputProps={{ maxLength: 6 }}
              onChange={(event: any) =>
                setValue(event.target.value.replace(/\D/g, ""))
              }
              id="outlined-basic"
              label="Token"
              variant="outlined"
            />
            <Button
              disabled={value?.length < 6 || email.length < 1 ? true : false}
              onClick={submitToken}
              style={{ marginTop: Spacing(2) }}
              variant="contained"
            >
              Confirmar
            </Button>
          </form>
        </div>
      </Styles.Content>
    </Styles.ImageBG>
  );
}

export default Login;
