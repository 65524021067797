import { paths } from "../navigation/navigate";

const pagesConfig = {
  notLogged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Login",
    }
   
  ],
  logged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Home",
      title: "MAPA DE RISCOS",

      working: true,
      show: true,
    },
  ],
};
export default pagesConfig;
